import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./Style.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import img1 from "../../Assets/images/Contents/KhanDayi-Pages/1 Intro.jpg";
import img2 from "../../Assets/images/Contents/Meta-Trove-Pages/1 SplashScreen.jpg";
import img3 from "../../Assets/images/Contents/Star-Words-Pages/1 INTRO.jpg";
import img4 from "../../Assets/images/Contents/Never-Thes-Same-Pages/1 Intro.jpg";
import img5 from "../../Assets/images/Contents/Cover-Beat-Pages/2 SplashScreen.jpg";
import { Link, useNavigate } from "react-router-dom";
import Project from "../../Data/Project.json";
import { TriangleStripDrawMode } from "three";

gsap.registerPlugin(ScrollTrigger);

function Index() {
  const [dynamicImports, setDynamicImports] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    animation();
    const importDynamicData = async () => {
      const dynamicData = await Promise.all(
        Project.map(async (item) => {
          const module = await import(
            `../../Assets/images/Contents/${item.folderName}/${item.imageName}`
          );
          return module.default;
        })
      );
      setDynamicImports(dynamicData);
      animation();
    };
    importDynamicData();
  }, []);
  const animation = () => {
    let workInfoItems = document.querySelectorAll(".work__photo-item");
    workInfoItems.forEach(function (item, index) {
      item.style.zIndex = workInfoItems.length - index;
    });

    gsap.set(".work__photo-item", {
      x: 0,
    });
    const animation = gsap.to(".work__photo-item:not(:last-child)", {
      x: 500,
      opacity: 0,
      stagger: 1.1,
      ease: "none",
    });
    ScrollTrigger.create({
      trigger: ".work",
      start: "top top",
      end: "bottom bottom",
      animation: animation,
      // markers:{
      //   startColor: "yellow", endColor: "blue",
      // },
      scrub: 1,
    });
  };
  const handlerNavigate = (id) => {
    console.log("handlerNavigate :", id);
    navigate(`project/${id}`);
  };
  return (
    <div className="wrapp">
      <div className="grid-contents">
        {/* {Project.map((item,index)=>( */}
        <div className="item_content">
          <img src={img1} />
          <div className="des" onClick={() => handlerNavigate(0)}>
            <div className="number">01</div>
            <div className="subject">{Project[0].titleHeader}</div>
            <div className="badge">{Project[0].badge}</div>
            <div className="tags">
              {Project[0].tags?.map((tag, i) => (
                <div className="tag" key={i}>
                  {tag}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="item_content">
          <img src={img2} />
          <div className="des" onClick={() => handlerNavigate(1)}>
            <div className="number">02</div>
            <div className="subject">{Project[1].titleHeader}</div>
            <div className="badge">{Project[1].badge}</div>
            <div className="tags">
              {Project[1].tags?.map((tag, i) => (
                <div className="tag" key={i}>
                  {tag}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="item_content large">
          <img src={img3} />
          <div className="des" onClick={() => handlerNavigate(2)}>
            <div className="number">03</div>
            <div className="subject">{Project[2].titleHeader}</div>
            <div className="badge">{Project[2].badge}</div>
            <div className="tags">
              {Project[2].tags?.map((tag, i) => (
                <div className="tag" key={i}>
                  {tag}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="item_content">
          <img src={img4} />
          <div className="des" onClick={() => handlerNavigate(3)}>
            <div className="number">04</div>
            <div className="subject">{Project[3].titleHeader}</div>
            <div className="badge">{Project[3].badge}</div>
            <div className="tags">
              {Project[3].tags?.map((tag, i) => (
                <div className="tag" key={i}>
                  {tag}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="item_content">
          <img src={img5} />
          <div className="des" onClick={() => handlerNavigate(4)}>
            <div className="number">05</div>
            <div className="subject">{Project[4].titleHeader}</div>
            <div className="badge">{Project[4].badge}</div>
            <div className="tags">
              {Project[4].tags?.map((tag, i) => (
                <div className="tag" key={i}>
                  {tag}
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* // ))} */}
      </div>
    </div>
  );
}

export default Index;
