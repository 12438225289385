import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './Style.scss'
import { FaBarsStaggered } from "react-icons/fa6";
function Navigation() {
  const navigate = useNavigate()
  const [isActive , setIsActive] = useState(false)
  
  return (
    <div className='navigation'>
     <input type="checkbox" id="main-navigation-toggle" className="btn btn--close" title="Toggle main navigation" />
      <label htmlFor="main-navigation-toggle">
        <span></span>
      </label>

      <nav id="main-navigation" className="nav-main">
        <ul className="menu">
          <li className="menu__item">
            <Link to={'/'} className="menu__link" >Home</Link>
          </li>
          <li className="menu__item">
            <Link to={'/about'} className="menu__link" >About</Link>
          </li>
          <li className="menu__item">
            <a className="menu__link" href="#0">Project</a>
            <ul className="submenu">
              <li className="menu__item">
                <div onClick={()=>navigate('/project/0')} className="menu__link">Khan Dayi</div>
              </li>
              <li className="menu__item">
                <div  onClick={()=>navigate('/project/1')} className="menu__link" >Meta Trove</div>
              </li>
              <li className="menu__item">
                <div  onClick={()=>navigate('/project/2')} className="menu__link" >Star Words</div>
              </li>
              <li className="menu__item">
                <div  onClick={()=>navigate('/project/3')} className="menu__link" >Never The Same</div>
              </li>
              <li className="menu__item">
                <div  onClick={()=>navigate('/project/4')} className="menu__link">Cover beat</div>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Navigation