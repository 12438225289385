import "./App.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Landing from "./components/Landing/Index.js";
import About from "./components/About/Index";
import Project from "./components/Project/Index";
import Background from "./components/components/Background/Index";
import Loading from "./components/components/Loading/Loading";
// import Scroll from './components/components/SmoothScrolling/Scroll';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route index element={<Landing />} />
      <Route path="about" element={<About />} />
      <Route path="ll" element={<Loading />} />
      <Route path="project/:id" element={<Project />} />
      <Route path="*" element={"404"} />
    </Route>
  )
);

function App() {
  return (
      <div className="App" id="smooth-wrapper">
        {/* <Scroll /> */}
        <Background />
        <div className="main" id="smooth-content">
          <RouterProvider router={router} />
        </div>
      </div>
  );
}

export default App;
