import "./Style.scss";
import React, {
  useRef,
  Suspense,
  useEffect,
  useState,
  useContext,
} from "react";
import Loading from "../components/Loading/Loading";
import img1 from "../../Assets/images/Contents/Background-Elements/1.png";
import img2 from "../../Assets/images/Contents/Background-Elements/13.png";
import img3 from "../../Assets/images/Contents/Background-Elements/15.png";
import img_head from "../../Assets/images/Contents/Background-Elements/Head.png";
import Navigation from "../components/Navigation/Navigation";
import Contents from "../Contents/Index";
import Stories from "../Stories/Index";
import Footer from "../Footer/Index";
import { gsap } from "gsap";
import SplitType from "split-type";
// extend({ Text });

function Landing() {
  const [loading, setLoading] = useState(true);

  const myText = new SplitType(".LastName");
  const name = useRef();

  useEffect(() => {
    animationTitles();
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, [loading]);

  const animationTitles = () => {
    gsap.to(".LastName", {
      y: 0,
      opacity: 1,
      stagger: 0.05,
      delay: 0.5,
      duration: 0.1,
    });
    gsap.to(".para", {
      x: 0,
      stagger: 0.05,
      delay: 0.75,
      duration: 0.1,
    });
    gsap.to(".familyName", {
      x: 0,
      stagger: 0.05,
      delay: 0.75,
      duration: 0.1,
    });
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div className="main">
        <Navigation />
        <div className="header">
          <div className="patterns">
            <img className="img-1" src={img1} />
            <img className="img-2" src={img2} />
            <img className="img-3" src={img3} />
            <img className="img-head" src={img_head} />
          </div>
          <div className="titleName">
            <strong ref={name} className="LastName">
              <div>BEH</div>
              <div className="space"></div>
              <div>NAM</div>
            </strong>
          </div>
          <div className="other-titles">
            <div className="para">
              <div>
                <p className="bold">Game</p>
                <p>UI/UX Designer</p>
              </div>
              <div>
                <p>{"<no code />"}</p>
                <p className="bold">dev</p>
              </div>
            </div>
            <div className="familyName">
              <div>Hesabi</div>
            </div>
          </div>

          {/* <div ref={ref} className='previw-canvas'>
             <Canvas camera={{ fov: 65, position: [0, 0, 9] }} eventSource={ref} style={{ pointerEvents: 'none' }} eventPrefix='client'>
              <Suspense fallback={null}>
                <color attach='background' args={['#36D995']} />
                <ambientLight intensity={0.65} />
                <directionalLight position={[0, 0, 100]} angle={0} penumbra={1} castShadow shadow-mapSize={[2024, 2024]} />
                <pointLight position={[10, 0, 0]} />
                <mesh>
                  <Model />
                </mesh>
              </Suspense>
              <Text position={[0, 5, -30]} fontSize={25} height={10} color={'blue'}>
                BEHNAM
              </Text>
              <Text position={[0, -10, -30]} fontSize={7} height={5} color={'pink'}>
                H     E       S                  A       B       I
              </Text>
            </Canvas> 
          </div>*/}
        </div>

        <Contents />
        <Stories />
        {/* <Contact /> */}
        <Footer />
      </div>
    );
  }
}

export default Landing;
