import React, { useEffect, useState } from "react";
import Footer from "../Footer/Index";
import Navigation from "../components/Navigation/Navigation";
import imgHeader from "../../Assets/images/Contents/Background-Elements/Bennys.png";
import Cover from "../../Assets/images/Contents/Background-Elements/About Behnam Picture.png";
import pattern_1 from "../../Assets/images/Contents/Background-Elements/15.png";
import pattern_2 from "../../Assets/images/Contents/Background-Elements/1.png";
import pattern_3 from "../../Assets/images/Contents/Background-Elements/13.png";
import pattern_4 from "../../Assets/images/Contents/Background-Elements/8.png";
import FDM from "../../Assets/images/Contents/Logos/FDM Group.png";
import Noyan from "../../Assets/images/Contents/Logos/Noyan.png";
import Kishmish from "../../Assets/images/Contents/Logos/Kishmish.png";
import Diaco from "../../Assets/images/Contents/Logos/diaco.png";
import figma from "../../Assets/images/Contents/Logos/Figma.png";
import xd from "../../Assets/images/Contents/Logos/XD.png";
import belender from "../../Assets/images/Contents/Logos/Blender.png";
import ai from "../../Assets/images/Contents/Logos/Illustrator.png";
import photoshop from "../../Assets/images/Contents/Logos/Photoshop.png";
import Sketchup from "../../Assets/images/Contents/Logos/Sketchup.png";
import unity from "../../Assets/images/Contents/Logos/Unity.png";

import "./Style.scss";
import Loading from "../components/Loading/Loading";

function About() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);
  if (loading) {
    return <Loading />;
  } else {
    return (
      <div className="about">
        <Navigation />
        <img className="pattern-1" src={pattern_1} />
        <img className="pattern-2" src={pattern_2} />
        <img className="pattern-3" src={pattern_3} />
        <img className="pattern-4" src={pattern_4} />
        <div className="headerr">
          <div>BEHNAM HESABI</div>
          <div>
            <img className="imgHeaderSmall" src={imgHeader} />
            <p>
              “Making the games I play, playing the games I make and the only
              thing I like more than playing video gams is making them.”
            </p>
          </div>
        </div>
        <div className="cover">
          <img src={Cover} />
        </div>
        <section className="sections">
          <div className="headers">
            <div className="number">01</div>
            <div className="title">About me</div>
          </div>
          <div className="details">
            <p> Hi, I’m Behnam an Iranian based game UI/UX designer. That might be my expertise but I am more than just my work.✨</p>

            <p>I know a lot about the process of making a digital product as I started my work in an indie game development company,
              and never left. 🎮 I had to fill in for multiple roles that were in association
              with being a UI designer, owning the term “Interdisciplinary Designer” (I hate titles).</p>

            <p>I take pride in saying I enjoy everything from an afternoon coffee to bungee jumping so you’ll never find me being bored.
              If I have a day to spare, you can find me camping in nature with my friends. 🌳🏞️ </p>

            <p>If all fails to amuse my endless lust for entertainment, I always have my stories to tell through my skills.
              And those stories are the reason why my tools are always sharp and evolving. 🔪❤️</p>

          </div>
        </section>
        <section className="sections">
          <div className="headers">
            <div className="number">02</div>
            <div className="title">Role</div>
          </div>
          <div className="details roles">
            <ul>
              <li>UI/UX Designer</li>
              <li>Unity Visual Developer</li>
              <li>Asset Designer</li>
              <li>Environmental Artist</li>
              <li>Art/Creative Director</li>
              <li>3D Modeler</li>
            </ul>
          </div>
        </section>
        <section className="sections">
          <div className="headers">
            <div className="number">03</div>
            <div className="title">Tools</div>
          </div>
          <div className="details">
            <div className="tools">
              <div className="item">
                <h5>Prototyping</h5>
                <div className="grid">
                  <img src={figma} />
                  <img src={xd} />
                </div>
              </div>
              <div className="item">
                <h5>Development</h5>
                <div className="grid">
                  <img src={unity} />
                  <img src={photoshop} />
                  <img src={ai} />
                </div>
              </div>
              <div className="item">
                <h5>Modeling</h5>
                <div className="grid">
                  <img src={belender} />
                  <img src={Sketchup} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sections">
          <div className="headers">
            <div className="number">02</div>
            <div className="title">Approach</div>
          </div>
          <div className="details Approach">
            <div className="row mt-100">
              <div className="">
                <div className="title">Timing</div>
                <p>
                  First and foremost for me as a creator is timing. I always
                  have a work schedule and I’m bound by it. I will starve BUT I
                  will make my deadlines.
                </p>
              </div>
              <div className="">
                <div className="title">Teamwork</div>
                <p>
                  Working with a team is always stimulating for me, as everyone
                  brings something to the table. My teammates can always count
                  on me to hold my end.
                </p>
              </div>
            </div>
            <div className="row mt-100">
              <div className="">
                <div className="title">Feedback</div>
                <p>
                  As a designer I’m no stranger to being biased about a project
                  I’m working on. I always turn to my friends and co-workers’
                  honest opinion to set me back on track.
                </p>
              </div>
              <div className="">
                <div className="title">Challenges</div>
                <p>
                  CHALLENGES, my ol' friend. You’ll never see me frowning over a
                  problem. I welcome anything that makes me think outside of the
                  box to find a solution.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="">
                <div className="title">Connection</div>
                <p>
                  In my daily routine I’m always catching up on my colleagues’
                  work to see if I missed anything or i would be of any help or
                  either to get inspired.
                </p>
              </div>
              <div className="">
                <div className="title">Best</div>
                <p>
                  Making the best possible outcome is never enough for me. I
                  love pushing both mine and my team’s boundaries to grow as
                  professionals.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="sections">
          <div className="headers">
            <div className="number">05</div>
            <div className="title">Teams & Collaboration</div>
          </div>
          <div className="collabirations">
            <a href="https://www.linkedin.com/company/diacostudios" className="item bold">
              <div className="logo">
                <img src={Diaco} />
              </div>
              <div className="title">Diaco Entertainment Studios</div>
            </a>
            <div className="other">
              <div className="header">In Assossiation with</div>
              <div className="logos">
                <div className="item">
                  <div className="logo">
                    <img src={FDM} />
                  </div>
                  <div className="title">FDM Group</div>
                </div>
                <div className="item">
                  <div className="logo">
                    <img src={Kishmish} />
                  </div>
                  <div className="title">Kishmish Animation Studio</div>
                </div>
                <div className="item">
                  <div className="logo">
                    <img src={Noyan} />
                  </div>
                  <div className="title">Noyan Games and Entertainment Co.</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default About;
