import React, { useEffect, useState } from 'react'
import './Style.scss';
import img4 from '../../Assets/images/pattern-4.svg'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import DataStories from '../../Data/Stories.json';



gsap.registerPlugin(ScrollTrigger);
function Index() {
  const [dynamicImportsRight, setDynamicImportsRight] = useState([]);
  const [dynamicImportsCenter, setDynamicImportsCenter] = useState([]);
  const [dynamicImportsLeft, setDynamicImportsLeft] = useState([]);

    useEffect(()=>{
          gsap.to(".one",{
            scrollTrigger:{
              trigger : ".one",
              start: "10% 30%",
              scrub: true,
              // markers: true,
            },
            y:-300,
            duration: 3,
          })
          gsap.to(".two",{
            scrollTrigger:{
              trigger : ".two",
              start: "10% 30%",
              scrub: true,
              // markers: true,
            },
            y:+100,
            duration: 3,
          })
          gsap.to(".three",{
            scrollTrigger:{
              trigger : ".three",
              start: "10% 30%",
              scrub: true,
              // markers: true,
            },
            y:-100,
            duration: 3,
          })

          const importDynamicData = async () => {
            const dynamicDataRight = await Promise.all(
              DataStories.Right.map(async (item) => {
                const module = await import(`../../Assets/images/Stories/${item.name}.jpg`);
                return module.default;
              })
            );
            const dynamicDataCenter = await Promise.all(
              DataStories.Center.map(async (item) => {
                const module = await import(`../../Assets/images/Stories/${item.name}.jpg`);
                return module.default;
              })
            );
            const dynamicDataLeft = await Promise.all(
              DataStories.Left.map(async (item) => {
                const module = await import(`../../Assets/images/Stories/${item.name}.jpg`);
                return module.default;
              })
            );
            setDynamicImportsRight(dynamicDataRight);
            setDynamicImportsCenter(dynamicDataCenter);
            setDynamicImportsLeft(dynamicDataLeft);
          };
          importDynamicData();
    },[]) 
    


  return (
    <div className='stories'>
        <div className='contentStory'>
            <div className='header'>
                <img className='pattern-4' src={img4} />
                <h4 className='contentStory-text'>I Love Stories & Visual Design Helps  Me Tell Mine.</h4>
            </div>
            <div className='grids'>
                <div className='column one'>
                  {dynamicImportsLeft.map((item)=>(
                    <div className='story' key={item.id}>
                      <img src={item} />
                    </div>
                  ))}
                </div>
                <div className='column two'>
                {dynamicImportsCenter.map(item =>(
                    <div className='story' key={item.id}>
                      <img src={item} />
                    </div>
                  ))}
                </div>
                <div className='column three'>
                {dynamicImportsRight.map((item,index) =>(
                    <div className='story' key={index}>
                      <img src={item} />
                    </div>
                  ))}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Index