import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataProject from "../../Data/DataProject.json";
import Loading from "../components/Loading/Loading";
import pattern_1 from "../../Assets/images/Contents/Background-Elements/10.png";
import pattern_2 from "../../Assets/images/Contents/Background-Elements/10.png";

import Illustrator from "../../Assets/images/Contents/Logos/Illustrator.png";
import Unity from "../../Assets/images/Contents/Logos/Unity.png";
import Blender from "../../Assets/images/Contents/Logos/Blender.png";
import Figma from "../../Assets/images/Contents/Logos/Figma.png";
import PhotoShop from "../../Assets/images/Contents/Logos/Photoshop.png";
import Navigation from "../components/Navigation/Navigation";

import "./Style.scss";

const icons = {
  Unity: <img src={Unity} />,
  Ai: <img src={Illustrator} />,
  Ps: <img src={PhotoShop} />,
  Figma: <img src={Figma} />,
  Blender: <img src={Blender} />,
};

// const data = [
//   {
//     id: 0,
//     name: 'Khan Dayi',
//     title: 'Overview',
//     des: 'Why do all the backgammon games have a casino theme with realistic graphics? I believe mobile games should be fun to look at with a simple idea of having fun playing them. That is what I set out to do here.',
//     links: [
//       {
//         nameImage: '1 About.jpg',
//         nameFolder: 'Cover-Beat-Pages',
//       },
//       {
//         nameImage: '2 SplashScreen.jpg',
//         nameFolder: 'Cover-Beat-Pages',
//       },{
//         nameImage: '3 Logo.jpg',
//         nameFolder: 'Cover-Beat-Pages',
//       },{
//         nameImage: '4 Feature.jpg',
//         nameFolder: 'Cover-Beat-Pages',
//       }
//     ],
//   },
// ];

function Project() {
  const { id } = useParams();
  const [dynamicImports, setDynamicImports] = useState([]);
  const video = useRef();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const importDynamicData = async () => {
      const dynamicData = await Promise.all(
        DataProject[id].links.map(async (item) => {
          const module = await import(
            `../../Assets/images/Contents/${DataProject[id].nameFolder}/${item.nameImage}`
          );
          return module.default;
        })
      );
      setDynamicImports(dynamicData);
    };
    importDynamicData();

    window.scrollTo(0, 0);
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, [id]);

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div className="project">
        <img src={pattern_1} className="pattern-1" />
        <Navigation />
        <div className="data">
          <div className="description">
            <h1>{DataProject[id].name}</h1>
            <h5>{DataProject[id].title}</h5>
            <p>{DataProject[id].des}</p>
          </div>
          
          <div className="loader-images">
            {dynamicImports.map((item, index) => (
              <div className="data-image" key={index}>
                {item.includes(".mp4") ? (
                  <video
                    ref={video}
                    className="video"
                    src={item}
                    autoPlay
                    muted
                  ></video>
                ) : (
                  <img src={item} key={index} alt={`Image ${index}`} />
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="frame">
          <div>
            <div className="title">Role</div>
            <div className="tags">
              {DataProject[id].role.map((tag, i) => (
                <div>{tag}</div>
              ))}
            </div>
            <div className="title">Tool</div>
            <div className="logos">
              {DataProject[id].tools.map((tool, i) => (
                <div key={i}>{icons[tool]}</div>
              ))}
            </div>
          </div>
          <div className="frames">
            {DataProject[id].figmaOption ? (
              <iframe
                style={{ width: "100%", height: "50vh" }}
                className="iframe"
                src={DataProject[id].figmaUrl}
                allowfullscreen
              ></iframe>
            ) : (
              <iframe
                style={{ width: "100%", height: "50vh" }}
                className="iframe"
                src={DataProject[id].figmaUrl}
                allowfullscreen
              ></iframe>
            )}
          </div>
          <img src={pattern_2} className="pattern-2" />
        </div>
        <div
          style={{ backgroundColor: DataProject[id].color }}
          className="sectionFooter"
          onClick={() => navigate("/project/" + DataProject[id].nextProjectId)}
        >
          <p>Next Project</p>
          <h1>{DataProject[id].nextProject}</h1>
        </div>
      </div>
    );
  }
}

export default Project;
