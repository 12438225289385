import React from "react";
import "./Style.scss";
import img_footer from "../../Assets/images/Contents/Background-Elements/bg-footer.jpg";
import { FaArrowRight } from "react-icons/fa";
import { LuDownload } from "react-icons/lu";
import {
  FaBehance,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa6";
import footer_img from '../../Assets/images/Contents/Background-Elements/Footer-bg.png';
import resume from '../../Assets/resume/Behnam-Hesabi-Game-UI-Designer.pdf';

function Footer() {
  const handleEmailClick = () => {
    const email = "hesabi.behnam@gmail.com";
    const subject = "Hello";
    const body = "This is the email body.";

    const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.open(mailtoUrl);
  };
  const downloadResume = () => {

  };


  return (
    <div className="footer">
      <img className="imgFooter" src={footer_img} />
      <div className="layer">
        <div className="leftSide">
          <h4>Ready To Team Up?</h4>
          <button onClick={handleEmailClick} className="custom-btn btn-14">
            <p>Let’s Talk</p>
          </button>
        </div>
        <div className="RightSide">
          <div className="boxs">
            <h5>Email :</h5>
            <p>Hesabi.behnam@gmail.com</p>
          </div>
          <div className="boxs">
            <h5>Number :</h5>
            <p>+989363159806</p>
          </div>
          <div className="downloadsocials">
            <div className="socials">
              <div
                onClick={() =>
                  window.open(
                    "https://ir.linkedin.com/in/behnam-hesabi-a5b016276",
                    "_blank"
                  )
                }
                className="social"
              >
                <FaLinkedinIn />
              </div>
              <div
                onClick={() =>
                  window.open(
                    "https://www.behance.net/behnamhesabi?tracking_source=search_projects",
                    "_blank"
                  )
                }
                className="social"
              >
                <FaBehance />
              </div>
              <div
                onClick={() =>
                  window.open("https://www.instagram.com/itsnotbehnam/", "_blank")
                }
                className="social"
              >
                <FaInstagram />
              </div>
              {/* <div onClick={()=>window.open('https://www.youtube.com/@behnamhesabi', '_blank')} className='social'><FaYoutube /></div> */}
            </div>

            <a href={resume} download className="downloadResume">
            <button className="custom-btn btn-14">
              <p>Resume <LuDownload/></p>
            </button>
            </a>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Footer;
